/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-else-return */
import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode, Pagination } from 'swiper';
import { graphql, useStaticQuery } from 'gatsby';
import {
  ErrorReview,
  SlideDecription,
  SlideTitle,
  SwiperContainer,
  SwiperWrapper,
} from './styled';
import StarRating from '../StarRating';
import { useDeviceWidth } from '../../hooks/useDeviceWidth';

// import required modules

function ReviewList() {
  const AllData = useStaticQuery(graphql`
    query {
      allStrapiReview {
        edges {
          node {
            id
            description
            rate
            name
          }
        }
      }
    }
  `);

  const data = AllData.allStrapiReview.edges.map((el) => el.node);

  const deviceWidth = useDeviceWidth();

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <SwiperContainer>
      <Swiper
        slidesPerView={deviceWidth > 960 ? 5 : 'auto'} // Количество слайдов для отображения
        spaceBetween={data.length > 1 ? 30 : 0}
        modules={[FreeMode]}
        freeMode={true}
        watchOverflow={true} // Прекращаем прокрутку при недостатке слайдов
      >
        {data.map((el) => {
          return (
            <SwiperSlide>
              <StarRating
                size={24}
                defaultRating={el?.rate}
                position="center"
                disabled
              />
              <SlideTitle>{el?.name}</SlideTitle>
              <SlideDecription>{el?.description}</SlideDecription>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </SwiperContainer>
  );
}

export default ReviewList;
