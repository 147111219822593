import * as React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import {
  ButtonClose,
  ErrorFormMessage,
  FakeInput,
  FakeInputLabel,
  Form,
  Label,
  Modal,
  Title,
  Wrapper,
  WrapperFake,
} from './styled';
import TextField from '../TextField';
import Button from '../Button';
import StarRating from '../StarRating';
import ReviewPopup from '../ReviewPopup';
// import axios from 'axios';

export default function Review({ isOpenModal, handleModal }) {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleModal();
    }
  };

  if (!isOpenModal) {
    return null;
  }

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();

  const [rate, setRate] = React.useState(0);
  // const [checked, setChecked] = React.useState(false);
  const [isPopUp, setIsPopUp] = React.useState(false);

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      rate,
    };

    try {
      const { status } = await axios.post(
        `${process.env.STRAPI_API_URL}/api/post-review`,
        formData,
      );

      if (status === 200) {
        setIsPopUp(true);
      }
    } catch (error) {
      console.error('Error posting review:', error);
    } finally {
    }

    // handleModal();
  };
  // const handleKeyDown = (event) => {
  //   const key = event.key;
  //   if (
  //     !/[0-9]/.test(key) &&
  //     key !== 'Backspace' &&
  //     key !== 'ArrowLeft' &&
  //     key !== 'ArrowRight'
  //   ) {
  //     event.preventDefault();
  //   }
  // }; // TODO

  return (
    <Wrapper onClick={handleOverlayClick}>
      <Modal>
        <Title> Залишити відгук </Title>
        <ButtonClose onClick={handleModal}>X</ButtonClose>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type="text"
            label="Номер телефону"
            register={register('phone', {
              required: 'Будь ласка введіть свій номер телефону',
              pattern: {
                value: /\d{4}\d{2}\d{2}\d{2}$/,
                message: 'Неправильний номер телефону',
              },
            })}
            prefix={<span>+38</span>}
            placeholder="(99) 999-99-99"
            name="phone"
            maxLength={10}
            error={errors.phone && errors.phone.message}
          />
          <TextField
            type="text"
            label="Ваше ім'я"
            register={register('name', {
              required: "Будь ласка введіть своє ім'я",
            })}
            name="name"
            error={errors.name && errors.name.message}
          />
          <TextField
            type="text"
            label="Ваш відгук"
            register={register('description')}
            name="description"
          />
          <Label> Як Ви оцінюєте продукт: </Label>
          <StarRating
            size={34}
            onSetRating={setRate}
            starContainerWidth={480}
          />
          <WrapperFake>
            {/* <FakeInput checked={checked} onClick={() => setChecked(!checked)}>
              {checked && <span>&#10003;</span>}
            </FakeInput>
            <FakeInputLabel onClick={() => setChecked(!checked)}>
              Дозволити публіковати на сайті */}
            {/* </FakeInputLabel> */}
          </WrapperFake>
          <Button variant="primary" type="submit">
            Залишити відгук
          </Button>
        </Form>
      </Modal>
      {isPopUp && (
        // <ReviewPopup onClick={handleOverlayClick} message={status === 200 ?" Дякуємо за ваш відгук! Він з'явиться на сайті як тільки пройде модерацію." : "Виникла помилка при відправці відгука. Спробуйте пізніше."} />
        <ReviewPopup
          onClick={handleOverlayClick}
          message="Дякуємо за ваш відгук!"
        />
      )}
    </Wrapper>
  );
}

Review.propTypes = {
  isOpenModal: PropTypes.func.isRequired,
  handleModal: PropTypes.func.isRequired,
};
