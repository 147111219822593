import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

export const Item = styled.div`
  position: relative;
  width: 560px;
  height: 320px;
  overflow: hidden;
  margin-bottom: 40px;

  &:hover div {
    scale: 1.05;
  }

  @media (max-width: 1200px) {
    width: 460px;
  }

  @media (max-width: 960px) {
    width: 320px;
    margin-bottom: unset;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @media (max-width: 576px) {
    width: 240px;
  }
`;

export const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  opacity: 0.85 !important;

  @media (max-width: 960px) {
    &:before,
    &:after {
      background-size: contain !important;
    }
  }
`;

export const Title = styled.h1`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
`;
