import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import GifSvg from '../../assets/icons/gifSwiper.svg';

export const Wrapper = styled.div`
  background: #000;
`;

export const Slider = styled(Swiper)`
  width: 100%;
  height: 500px;

  .swiper-button-prev {
    left: 30px;
  }

  .swiper-button-next {
    right: 30px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: #000;
    width: 40px;
    height: 40px;
    border: 1px solid #222;
    background: rgba(134, 183, 209, 1);
    border-radius: 50%;
    transition: all 0.3s;

    @media (max-width: 576px) {
      width: 26px;
      height: 26px;
    }

    &::after {
      font-size: 20px;

      @media (max-width: 576px) {
        font-size: 15px;
      }
    }
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    background: transparent;
  }

  .swiper-pagination-bullet-active {
    background: #fff;
  }
`;

export const Slide = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  text-shadow: 0 2px 5px #000;
  text-align: center;
  color: #fff;
  height: 100%;

  div {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }
`;

export const CustomSlide = styled(SwiperSlide)`
  display: flex;
  justify-content: center;
  align-items: end;
  text-shadow: 0 2px 5px #000;
  text-align: center;
  ${({ img }) => `
    background: linear-gradient(rgba(15, 15, 15, 0.3), rgba(0, 0, 0, 0.3)), url(${img}) no-repeat center center;
    background-size: cover;
  `}
  color: #fff;
  height: 100%;
`;

export const TextSlide = styled.span`
  max-width: 620px;
  padding-bottom: 50px;
  font-size: 44px;
  line-height: 1.23;
  font-weight: 700;
  z-index: 2;

  @media (max-width: 960px) {
    font-size: 35px;
  }

  @media (max-width: 576px) {
    font-size: 25px;
  }
`;

export const SushiTypes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 30px;

  @media (max-width: 960px) {
    display: none;
  }
`;

export const SwiperSushiTypes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
`;

export const Gif = styled(GifSvg)`
  display: none;
  width: 42px;
  height: 42px;
  margin-top: 30px;

  @media (max-width: 960px) {
    display: block;
  }
`;

export const SwiperSushi = styled(Swiper)`
  display: none;

  @media (max-width: 960px) {
    display: block;
  }

  .swiper-slide {
    width: 320px;

    @media (max-width: 576px) {
      width: 240px !important;
    }
  }
`;

export const Discount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-top: 20px;

  @media (max-width: 960px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 45px;
  }

  @media (max-width: 576px) {
    padding: 0 20px;
  }
`;

export const ImageDiscount = styled.div`
  width: 560px;
  height: 560px;
  margin-right: 40px;

  @media (max-width: 1200px) {
    max-width: 460px;
    height: auto;
  }

  @media (max-width: 960px) {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
    margin-right: 0;
  }
`;

export const WrapperDiscountInfo = styled.div`
  width: 560px;

  @media (max-width: 1200px) {
    max-width: 460px;
  }

  @media (max-width: 960px) {
    max-width: 100%;
  }
`;

export const HeaderDiscount = styled.h1`
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 28px;
  max-width: 65%;

  @media (max-width: 1200px) {
    font-size: 32px;
    max-width: 100%;
  }

  @media (max-width: 960px) {
    font-size: 28px;
  }
`;

export const Description = styled.div`
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.55;

  @media (max-width: 1200px) {
    font-size: 18px;
  }

  @media (max-width: 640px) {
    font-size: 16px;
    line-height: 1.45;
  }
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
`;

export const WrapperModal = styled.div`
  position: relative;
  padding: 40px;
  width: 480px;
  background: #000;
  color: #86b7d1;
  text-align: center;
  box-shadow: 0 0 10px 0 #86b7d1;
  border-radius: 5px;
  svg {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 15px;
    height: 15px;
    cursor: pointer;

    g {
      stroke: #fff;
      fill: #fff;
    }
  }

  @media (max-width: 576px) {
    width: 280px;
    font-size: 16px;
  }
`;

export const Info = styled.div`
  font-weight: 400;
  font-size: 20px;
`;
