import styled from 'styled-components';

export const SwiperContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
  color: #86b7d1;
  width: 100%;
  align-items: stretch;
  overflow: hidden; /* Скрываем переполнение */
  @media (max-width: 578px) {
    width: 300px;
  }

  .swiper {
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: linear-gradient(to bottom, #000000, #0f0f0f);
    border-radius: 15px;
    /* Center slide text vertically */
    display: flex;
    padding: 45px 35px;
    flex-direction: column;
    width: 16rem !important;

    height: 230px;
    justify-content: space-between;
    box-shadow: inset 1px 1px 2px 2px rgba(255, 255, 255, 0.5);
    @media (max-width: 1024px) {
      width: 250px !important;
      padding: 50px 30px;
    }
    @media (max-width: 768px) {
      width: 200px !important;
      padding: 40px 20px;
    }
    overflow: auto;
    -webkit-scrollbar {
      width: 0;
      background: transparent;
    }
    scrollbar-width: none;
    scrollbar-color: transparent transparent; /* Делаем скроллбар невидимым  */
  }
  .card-review {
    height: 100%;
  }
`;
export const SlideTitle = styled.p`
  font-size: 18px;
  margin-bottom: 10px;
  color: #86b7d1;
`;

export const SlideDecription = styled.p`
  font-size: 14px;
  color: #fff;
`;

export const ErrorReview = styled.div`
  p {
    font-size: 36px;
    margin-bottom: 100px;
  }
`;
