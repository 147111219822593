import * as React from 'react';
import PropTypes from 'prop-types';
import { getImage } from 'gatsby-plugin-image';
import { Item, Image, Title } from './styled';

export default function MenuItem({ img, title }) {
  return (
    <Item>
      <Image image={getImage(img)} alt={title} />
      <Title>{title}</Title>
    </Item>
  );
}

MenuItem.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
