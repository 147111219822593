import * as React from 'react';
import PropTypes from 'prop-types';
import { ButtonClosePopUp, Container, Text, Wrapper } from './styled';

function ReviewPopup({ onClick, message }) {
  return (
    <Wrapper onClick={onClick}>
      <Container>
        <ButtonClosePopUp onClick={onClick}>X</ButtonClosePopUp>
        <Text>{message}</Text>
      </Container>
    </Wrapper>
  );
}

export default ReviewPopup;

ReviewPopup.propTypes = {
  onClick: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};
