import * as React from 'react';
import { useState, useEffect } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper';
import { useStaticQuery, graphql, Link } from 'gatsby';
import MenuItem from '../../component/MenuItem';
import Container from '../../component/Container';
import { useDeviceWidth } from '../../hooks/useDeviceWidth';
import CloseSvg from '../../assets/icons/close.svg';
import {
  Wrapper,
  Slider,
  Slide,
  SushiTypes,
  SwiperSushiTypes,
  Gif,
  SwiperSushi,
  Discount,
  ImageDiscount,
  HeaderDiscount,
  Description,
  WrapperDiscountInfo,
  TextSlide,
  Modal,
  WrapperModal,
  Info,
} from './styled';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import ReviewsSection from '../Reviews';

export default function Home() {
  const deviceWidth = useDeviceWidth();
  const {
    strapiMainPageWindow: {
      isPosted,
      modalText: {
        data: { modalText },
      },
    },
    allStrapiSushiType: { nodes: sushiType },
    strapiDiscountInfo,
    allStrapiSlider: { nodes: slides },
    allStrapiMobileSlider: { nodes: sliderMobile },
  } = useStaticQuery(
    graphql`
      query HomeQuery {
        strapiMainPageWindow {
          modalText {
            data {
              modalText
            }
          }
          isPosted
        }
        strapiDiscountInfo {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 560, height: 560)
              }
            }
          }
          header
          description {
            data {
              description
            }
          }
        }
        allStrapiSushiType(sort: { fields: priority }) {
          nodes {
            id
            name
            urlname
            photo {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
        allStrapiSlider {
          nodes {
            text
            id
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
        allStrapiMobileSlider {
          nodes {
            id
            text
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    `,
  );

  const [isModal, setIsModal] = useState(false);
  const sushiTypeNew = sushiType.slice(0, -2);
  useEffect(() => {
    if (!sessionStorage.getItem('modal')) {
      setIsModal(true);
      sessionStorage.setItem('modal', 'true');
    }
  }, []);

  const {
    image: { localFile },
    description: {
      data: { description },
    },
    header,
  } = strapiDiscountInfo;
  const slider = deviceWidth > 960 ? slides : sliderMobile;

  return (
    <Wrapper>
      <Slider
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop
        speed={1000}
        pagination={{
          clickable: true,
        }}
        navigation
        modules={[Pagination, Navigation, Autoplay]}
      >
        {slider.map(({ id, text, image: { localFile: slide } }) => (
          <SwiperSlide key={id}>
            <Slide>
              <GatsbyImage image={getImage(slide)} alt={text} />
              <TextSlide>{text}</TextSlide>
            </Slide>
          </SwiperSlide>
        ))}
      </Slider>
      <Container>
        <SushiTypes>
          {sushiTypeNew.map(
            ({ id, name, photo: { localFile: swiperImage }, urlname }) => (
              <Link key={id} to={`/${urlname}`}>
                <MenuItem img={swiperImage} title={name} />
              </Link>
            ),
          )}
        </SushiTypes>
        <SwiperSushiTypes>
          <Gif />
        </SwiperSushiTypes>
        <SwiperSushi slidesPerView="auto" spaceBetween={50}>
          {sushiTypeNew.map(
            ({ id, name, photo: { localFile: swiperImage }, urlname }) => (
              <SwiperSlide key={id}>
                <Link key={id} to={`/${urlname}`}>
                  <MenuItem img={swiperImage} title={name} />
                </Link>
              </SwiperSlide>
            ),
          )}
        </SwiperSushi>
        <Discount>
          <ImageDiscount>
            <GatsbyImage image={getImage(localFile)} alt="discount" />
          </ImageDiscount>
          <WrapperDiscountInfo>
            <HeaderDiscount>{header}</HeaderDiscount>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </WrapperDiscountInfo>
        </Discount>

        {isModal && isPosted && (
          <Modal onClick={() => setIsModal(false)}>
            <WrapperModal>
              <Info dangerouslySetInnerHTML={{ __html: modalText }} />
              <CloseSvg onClick={() => setIsModal(false)} />
            </WrapperModal>
          </Modal>
        )}
        <ReviewsSection />
      </Container>
    </Wrapper>
  );
}
