import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../component/layout';
import Seo from '../component/seo';
import Home from '../sections/Home/Home';

export default function IndexPage() {
  const {
    strapiMetaPage: { header, description },
  } = useStaticQuery(
    graphql`
      query Home {
        strapiMetaPage(page_name: { eq: "/" }) {
          header
          description
          page_name
        }
      }
    `,
  );

  return (
    <Layout>
      <Seo title={header} description={description} />
      <Home />
    </Layout>
  );
}
