import styled from 'styled-components';

const ReviewContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  button {
    border: 1px solid #fff;
    /* background-color: #fff; */
  }
`;

export default ReviewContainer;
