import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 0.3s ease forwards;
  z-index: 1000;
  &::-webkit-scrollbar {
    width: 0;
  }
  -ms-overflow-style: none;
`;

export const Modal = styled.div`
  margin: 65px auto;

  flex-direction: column;
  max-width: 560px;
  width: 100%;
  padding: 40px;
  background: #fff;
  z-index: 1000;
  overflow-y: auto; /* Включение прокрутки */
  height: 75vh;
  position: relative;
  animation: ${slideDown} 0.3s ease forwards;

  -webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  scrollbar-width: none;
  scrollbar-color: transparent transparent; /* Делаем скроллбар невидимым  */

  @media (max-width: 1024px) {
    height: 90vh;
  }
  @media (max-width: 768px) {
    max-width: 460px;
  }

  @media (max-width: 576px) {
    width: 100%;
    padding: 30px;
  }

  @media (max-width: 440px) {
    padding: 20px;
  }
`;
export const Title = styled.h1`
  color: #000;
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 24px;
  line-height: 1.35;
  font-weight: 600;

  @media (max-width: 1200px) {
    font-size: 22px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;
export const Form = styled.form`
  width: 100%;

  button {
    margin-bottom: 0;
    margin-top: 40px;
    width: 100%;
    border-radius: 0;
  }
`;

export const ErrorFormMessage = styled.div`
  margin: -25px 0 30px 0;
  font-size: 13px;
  color: red;
`;

export const Label = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  color: #000;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 20px;

  @media (max-width: 578px) {
    font-size: 12px;
  }
`;

export const WrapperFake = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 30px;
`;

export const FakeInput = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 2px solid #86b7d1;
  transition: all 0.2s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ checked }) => (checked ? '1' : '0.6')};
  flex-shrink: 0;

  span {
    color: #86b7d1;
  }
`;

export const FakeInputLabel = styled.div`
  color: #000;
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;

  @media (max-width: 578px) {
    font-size: 12px;
  }
`;

export const ButtonClose = styled.div`
  position: absolute;
  top: 40px;
  right: 40px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  @media (max-width: 1200px) {
    top: 42px;
    right: 35px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    top: 24px;
    right: 24px;
  }
`;
